export default {
  methods: {
    formatNumber(val) {
      if (!val) {
        return val
      }
      const newValue = val.toString().replace(/[.]/g, '')
      return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    formatNumberPrice(val) {
      if (this.isExistComma(val)) {
        const arrValue = val.split(',')
        let newValue = ''
        arrValue.forEach((value, index) => {
          if (index === 0) {
            newValue = this.formatNumber(arrValue[index])
          } else if (index === 1 && value) {
            newValue = newValue + ',' + arrValue[index]
          }
        })
        return newValue
      } else {
        return this.formatNumber(val)
      }
    },

    isExistComma(val) {
      return String(val).indexOf(',') > -1
    },
    isNumberPrice(evt, value) {
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode

      if (this.isExistComma(value) && charCode === 44) {
        evt.preventDefault()
      }

      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 44) {
        evt.preventDefault()
      }

      return true
    },
  },
}
